// import "normalize.css";
import Vue from "vue";
import Index from "./pages/index.vue";
import VueTimeline from "@growthbunker/vuetimeline";
Vue.use(VueTimeline);

import { LayoutPlugin } from 'bootstrap-vue';
Vue.use(LayoutPlugin);

import { ButtonPlugin } from 'bootstrap-vue'
Vue.use(ButtonPlugin);

import { CardPlugin } from 'bootstrap-vue'
Vue.use(CardPlugin);

import { ModalPlugin } from 'bootstrap-vue'
Vue.use(ModalPlugin);


let app = new Vue({
    el:"#app",
    render: h=> h(Index),
})
