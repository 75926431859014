<template>
    <p class="typing" v-if="text" v-html="display_text" :style="{'color':font_color}"></p>
</template>

<script>
    export default {
        name: "typewrighter",
        data:function(){
          return {
              display:"",
              text_array:[],
              current:0,
          }
        },
        watch:{
          text(){
              console.log("update text");
              let update_delay = this.update_delay;
              let self = this;
              self.current = 0;
              self.display = "";
          }
        },
        mounted() {
            // let self = this;
            // let speed = this.speed;
            //
            // this.text_array = this.splite_text(this.text);
            //
            // if ( this.current < this.text_array.length) {
            //     setTimeout(function(){
            //         self.current++;
            //         console.log(self.current,self.text_array.length);
            //     }, speed);
            // }
        },
        computed:{
            _display(){
              return this.display;
            },
            display_text() {

                let i = this.current;
                let self = this;
                let txt = this.text;
                let speed = this.speed;
                let display = this.display;
                let done = this.done;
                let update_delay = this.update_delay;

                if (i < txt.length) {

                    setTimeout(function(){
                        self.typewords();
                    }, speed);

                }else{

                    setTimeout(function(){
                        self.$emit('finish');
                    }, update_delay);

                }

                if(this.show_symbol){
                    return this.display + `<span class='typing_mark' style='background:${this.font_color}'></span>`;
                }else{
                    return this.display;
                }


            }
        },
        props:{
            text:{
                default:"",
                type:String,
            },
            speed:{
              default:80,
              type:Number,
            },
            update_delay:{
                default:1000,
                type:Number,
            },
            font_color:{
                default:"#FFF",
                type:String,
            },
            show_symbol:{
                default:true,
                type:Boolean
            }
        },
        methods:{
            splite_text(text){
                return text.split("");
            },
            typewords(){
                let txt = this.text;
                let i = this.current;
                if(txt.charAt(i) == "|"){
                    this.display += "<br>";
                }else{
                    this.display += txt.charAt(i);
                }

                this.current++;

            }
        }
    }
</script>

<style scoped>
    .typing{
        color:#FFF;
        line-height: 1.5;
        font-size:1.2rem;
    }
</style>
<style lang="scss">
    @keyframes blinker {
        100% {
            opacity: 0;
        }
    }
    .typing{
        .typing_mark{
            width:10px;
            height: 5px;
            margin-left:3px;
            animation: blinker 500ms 500ms linear infinite;
            display: inline-block;
        }
    }
</style>