<template>
    <span :style="style()">
<!--        {{ countDown }} - {{day}} days - {{hour}} hours - {{minute}} minutes - {{second}} seconds-->
        <slot :time="time_formated">
            {{time}} - {{time_formated}}
        </slot>
<!--        <slot :ok="time_formated" name="other"></slot>-->
    </span>

</template>

<script>
    export default {
        name: "timer",
        data:function(){

            let _now = Math.round((new Date()).getTime() / 1000);

            return {
                now:_now,
                countDown : 10,
                day:null,
                hour:null,
                minute:null,
                second:null
            }
        },
        watch:{

        },
        mounted() {

            this.countDownTimer();
        },
        computed:{
            time_formated(){
                return {
                    d:this.day,
                    h:this.hour,
                    m:this.minute,
                    s:this.second
                }
            }
        },
        props:{
            font_color:{
                type:String,
                default:"#FFF"
            },
            time:{
                type:Number,
                default:30
            }
        },
        methods:{
            countDownTimer() {

                let _now = Math.round((new Date()).getTime() / 1000);

                if(this.countDown > 0) {
                    setTimeout(() => {
                        this.countDown -= 1
                        this.countDownTimer()
                    }, 1000)
                }else{
                    this.countDown = 0;
                }

                this.countDown = this.time - _now;
                this.day =  Math.floor(this.countDown / (24 * 60 * 60));
                this.hour =  Math.floor(this.countDown % (24 * 60 * 60 ) / (60 * 60 ));
                this.minute =  Math.floor((this.countDown % (60 * 60 ) ) / 60 );
                this.second = Math.floor( this.countDown % 60 );
            },
            style(){

                return {
                    color : this.font_color
                }
            }
        }
    }
</script>

<style scoped>

</style>
